<template>
  <v-item-group multiple id="productCompare">
    <v-card elevation="5" class="compare">
      <div class="compare-title">
        <v-toolbar class="compare-title-tool">
          <v-icon style="margin-right: 10px;">mdi-compare</v-icon>
          <v-toolbar-title>Product Compare</v-toolbar-title>
          <v-divider class="mx-6" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="grey" style="color:white;" @click="compareBack">
            <v-icon left>
              mdi-arrow-left-circle
            </v-icon>
            Back
          </v-btn>
        </v-toolbar>
      </div>
      <v-row class="compare-row1 justify-content-center">
        <v-col v-if="getLoadCompare === true" cols="12" class="compare-col1">
          <v-sheet class="mx-auto compare-sheet">
            <v-slide-group
              v-model="model"
              class="pa-0 compare-group"
              show-arrows
            >
              <v-slide-item
                v-for="(item, index) in getCompareItems"
                :key="index"
                class="compare-item"
              >
                <v-card
                  class="mx-auto"
                  color="grey lighten-4"
                  :width="getCompareItems.length > 3 ? 300 : 420"
                >
                  <v-img
                    v-if="getCompareItems.length > 3"
                    aspect-ratio="1.7"
                    contain
                    :src="
                      item.photo.photo_1 === null
                        ? `${getProductImageLink}products/photo_1/ProductPhoto-g8extzpjvxqb5hcpc51be.png`
                        : `${getProductImageLink}products/photo_1/${photoFilter(
                            item.photo.photo_1
                          )}`
                    "
                  >
                  </v-img>
                  <v-img
                    v-else
                    width="70%"
                    height="150px"
                    style="margin: auto;"
                    aspect-ratio="1.7"
                    contain
                    :src="
                      item.photo.photo_1 === null
                        ? `${getProductImageLink}products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png`
                        : `${getProductImageLink}products/photo_1/${photoFilter(
                            item.photo.photo_1
                          )}`
                    "
                  >
                  </v-img>
                  <v-card-title
                    style="background: #BBDEFBb6; font-size:14px; color:#D81B60; font-weight:bold; padding:3px; width:100%; line-height:17px; position:absolute; top:0; left:0;"
                  >
                    {{ item.vendor.name }}
                  </v-card-title>
                  <v-card-text
                    class=" compare-card-text"
                    style="position: relative;"
                  >
                    <h6
                      class=" orange--text mb-2"
                      style="max-height:50px; overflow:auto; font-size:14px;"
                    >
                      {{ item.product.name }}
                    </h6>
                    <div class=" title mb-2">
                      <h6
                        style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                      >
                        Ordered by: <br />
                        <p
                          style="font-size:14px; font-weight:500; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                        >
                          {{
                            item.last_purchase_plant === null ||
                            item.last_purchase_plant === ''
                              ? '-'
                              : item.last_purchase_plant
                          }}
                        </p>
                      </h6>
                    </div>
                    <div class=" title mb-2">
                      <h6
                        style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                      >
                        Last Ordered: <br />
                        <p
                          style="font-size:14px; font-weight:500; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                        >
                          {{
                            item.last_ordered === null ||
                            item.last_ordered === ''
                              ? '-'
                              : item.last_ordered | moment('DD MMMM YYYY')
                          }}
                        </p>
                      </h6>
                    </div>
                    <div class="title mb-2" style="">
                      <h6
                        style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                      >
                        Last Price: <br />
                        <p
                          style="font-size:14px; font-weight:500; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                        >
                          <span style="margin-right:5px;">{{
                            item.currency === null ? '' : item.currency.symbol
                          }}</span>
                          {{
                            item.last_price === null || item.last_price === ''
                              ? '-'
                              : price(item.last_price)
                          }}
                        </p>
                      </h6>
                    </div>
                    <div class=" title mb-2">
                      <h6
                        style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                      >
                        Code: <br />
                        <p
                          style="font-size:14px; font-weight:500; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                        >
                          {{ item.product.code }}
                        </p>
                      </h6>
                    </div>
                    <div class=" title mb-2">
                      <h6
                        style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                      >
                        Category: <br />
                        <p
                          style="font-size:14px; font-weight:400; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                        >
                          {{
                            item.product.category !== null
                              ? item.product.category
                              : '-'
                          }}
                        </p>
                      </h6>
                    </div>
                    <div class=" title mb-2">
                      <h6
                        style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                      >
                        Merk: <br />
                        <p
                          style="font-size:14px; font-weight:400; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                        >
                          {{
                            item.merk !== '' || item.merk !== null
                              ? item.merk
                              : '-'
                          }}
                        </p>
                      </h6>
                    </div>
                    <div class=" title mb-2">
                      <h6
                        style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                      >
                        Unit: <br />
                        <p
                          style="font-size:14px; font-weight:400; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                        >
                          {{ item.uom_name }}
                        </p>
                      </h6>
                    </div>
                    <div v-if="item.catalogue_type === 1" class="title mb-2">
                      <h6
                        style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 2px 3px;  min-height:100px;"
                      >
                        Spesification ({{ item.catalogue_type_name }}): <br />
                        <p
                          style="font-size:12px; font-weight:500; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                        ></p>
                      </h6>
                    </div>
                    <div
                      v-if="item.catalogue_type === 2"
                      class="title mb-2"
                      style="background:#E0E0E0; borderRadius:5px; padding: 2px 3px;"
                    >
                      <h6
                        style="font-size:12px; font-weight:bold; border-bottom:1px solid #9e9e9e; padding: 0 0 7px;"
                      >
                        Spesification ({{ item.catalogue_type_name }}): <br />
                      </h6>
                      <div
                        style="border:1px solid #9e9e9e; border-top:none;border-left:none; margin-top:-8px;"
                      >
                        <div
                          v-for="(item, index) in getSpecForCompare[
                            spesification(getSpecForCompare, item)
                          ]"
                          :key="index"
                          style="display:flex; flex-direction:row; position:relative; margin-bottom:-16px;"
                        >
                          <p
                            style="font-size:12px; color:black;width:25%; text-align:left; line-height:normal; padding:3px;border:1px solid #9e9e9e;background:#E3F2FD;  border-left:2px solid #9e9e9e;"
                          >
                            {{ item.spesification_attribute.name }}
                          </p>
                          <p
                            style="font-size:12px; font-weight:500;width:75%;text-align:left;line-height:normal;padding:3px;border:1px solid #9e9e9e; "
                          >
                            {{ item.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>

        <v-card
          v-else
          class="mx-auto"
          width="99%"
          height="260"
          style="display:flex; justify-content: center; align-items:center; font-size:12px;"
        >
          <v-progress-circular
            :size="170"
            :width="10"
            color="blue"
            indeterminate
            >Please wait</v-progress-circular
          >
        </v-card>
      </v-row>
    </v-card>
  </v-item-group>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    model: [],
    specIsTrue: false
  }),
  computed: {
    ...mapGetters([
      'getProductCompareStat',
      'getCompareItems',
      'getLoadCompare',
      'getProductImageLink',
      'getSpecForCompare'
    ])
  },
  methods: {
    ...mapActions(['specForCompare']),
    ...mapMutations([
      'productCompare',
      'setCompareLoading',
      'setProductCompare'
    ]),
    compareBack() {
      this.setProductCompare([])
      this.productCompare(false)
      this.setCompareLoading(false)
    },
    photoFilter(link) {
      if (link === null || link === '') {
        return ''
      } else {
        const lastIndxOf = link.lastIndexOf('/')
        const str = link.substring(lastIndxOf + 1)
        if (str === 'null') {
          return ''
        } else {
          return str
        }
      }
    },
    price(val) {
      const reverse = val
        .toString()
        .split('')
        .reverse()
        .join('')
      var ribuan = reverse.match(/\d{1,3}/g)
      return ribuan
        .join(',')
        .split('')
        .reverse()
        .join('')
    },
    spesification(arr, item) {
      for (let i = 0; i < arr.length; i++) {
        console.log(arr[i])
        if (arr[i] !== undefined) {
          const result = arr[i].find(
            ({ vendor }) => vendor.name === item.vendor.name
          )
          if (result) {
            return i
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" src="../../../assets/css/productManage.scss"></style>
